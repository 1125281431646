import { jsxs as v, jsx as t, Fragment as Y } from "react/jsx-runtime";
import { AlertGroup as tt, Alert as nt, AlertVariant as re, AlertActionCloseButton as rt, Page as lt, Modal as Pe, ModalVariant as at, Button as D, TextContent as it, Text as ae, TextVariants as st, Spinner as ot, FormHelperText as ue, HelperText as he, HelperTextItem as me, Popover as ct, Icon as ie, FormGroup as Ve, NumberInput as dt, ValidatedOptions as M, InputGroup as pe, InputGroupItem as se, TextInput as Q, Select as X, MenuToggle as W, MenuToggleStatus as fe, SelectList as Z, SelectOption as ge, TextInputGroup as Me, TextInputGroupMain as Ne, ChipGroup as qe, Chip as Le, TextInputGroupUtilities as Be, Switch as ut, TextArea as _e, Title as ht, Card as mt, CardHeader as pt, CardTitle as ft, CardBody as gt, Grid as yt, GridItem as xe, PageSection as vt, JumpLinks as bt, JumpLinksItem as Ct, ButtonVariant as we, Checkbox as It, Radio as kt, MenuFooter as xt, Dropdown as wt, DropdownList as At, DropdownItem as Ae, Brand as Tt, Avatar as St } from "@patternfly/react-core";
import { createContext as ye, useContext as ve, useState as T, useCallback as He, useEffect as be, useMemo as P, useRef as ee, forwardRef as Ft, useId as Ot, Fragment as Ue, Children as $e } from "react";
import { useTranslation as Ce } from "react-i18next";
import Rt from "keycloak-js";
import { ExclamationCircleIcon as Dt, HelpIcon as Te, EyeIcon as Et, EyeSlashIcon as Pt, TimesIcon as Ge, CubeIcon as Vt, PaypalIcon as Mt, InstagramIcon as Nt, BitbucketIcon as qt, MicrosoftIcon as Lt, TwitterIcon as Bt, StackOverflowIcon as _t, OpenshiftIcon as Ht, LinkedinIcon as Ut, GoogleIcon as $t, GitlabIcon as Gt, FacebookSquareIcon as Wt, GithubIcon as Kt, MinusCircleIcon as jt, PlusCircleIcon as zt, EllipsisVIcon as Jt } from "@patternfly/react-icons";
import { useFormContext as te, Controller as U, useController as Ie, FormProvider as Yt, useWatch as Qt } from "react-hook-form";
import { get as G } from "lodash-es";
import { PageHeader as Xt, PageHeaderTools as Zt, PageHeaderToolsGroup as en, PageHeaderToolsItem as le } from "@patternfly/react-core/deprecated";
import j from "@patternfly/react-styles/css/components/Avatar/avatar";
import { css as tn } from "@patternfly/react-styles";
import './main.css';const We = ye(void 0), rr = () => ve(We), nn = ({ children: e }) => {
  const [n, r] = T([]), l = (s) => {
    r((c) => c.filter((o) => o.id !== s));
  }, a = (s, c = re.success, o) => {
    r([
      {
        id: Math.random() * 100,
        message: s,
        variant: c,
        description: o
      },
      ...n
    ]);
  }, i = (s) => {
    a(s, re.danger);
  };
  return /* @__PURE__ */ v(We.Provider, { value: { addAlert: a, addError: i }, children: [
    /* @__PURE__ */ t(tt, { isToast: !0, "data-testid": "alerts", children: n.map(({ id: s, variant: c, message: o, description: d }) => /* @__PURE__ */ t(
      nt,
      {
        isLiveRegion: !0,
        variant: re[c],
        variantLabel: "",
        title: o,
        actionClose: /* @__PURE__ */ t(
          rt,
          {
            title: o,
            onClose: () => l(s)
          }
        ),
        timeout: !0,
        onTimeout: () => l(s),
        children: d && /* @__PURE__ */ t("p", { children: d })
      },
      s
    )) }),
    e
  ] });
}, rn = (e) => {
  const { t: n } = Ce(), r = e.error, l = ln(r);
  function a() {
    location.href = location.origin + location.pathname;
  }
  return /* @__PURE__ */ t(lt, { children: /* @__PURE__ */ t(
    Pe,
    {
      variant: at.small,
      title: n("somethingWentWrong"),
      titleIconVariant: "danger",
      showClose: !1,
      isOpen: !0,
      actions: [
        /* @__PURE__ */ t(D, { variant: "primary", onClick: a, children: n("tryAgain") }, "tryAgain")
      ],
      children: /* @__PURE__ */ v(it, { children: [
        /* @__PURE__ */ t(ae, { children: n("somethingWentWrongDescription") }),
        l && /* @__PURE__ */ t(ae, { component: st.small, children: l })
      ] })
    }
  ) });
};
function ln(e) {
  return typeof e == "string" ? e : e instanceof Error ? e.message : null;
}
function an(e, n) {
  const r = ye(n);
  return r.displayName = e, r;
}
function sn(e) {
  return e != null;
}
function on(e) {
  const n = ve(e);
  if (sn(n))
    return n;
  throw new Error(
    `No provider found for ${e.displayName ? `the '${e.displayName}'` : "an unknown"} context, make sure it is included in your component hierarchy.`
  );
}
function cn(e, n, r) {
  const [l, a] = T(
    () => e.getItem(n) ?? r
  ), i = He((s) => {
    a(s), e.setItem(n, s);
  }, []);
  return be(() => {
    a(e.getItem(n) ?? r), window.addEventListener("storage", s);
    function s(c) {
      c.storageArea === e && (c.key === null || c.key === n) && a(c.newValue ?? r);
    }
    return () => window.removeEventListener("storage", s);
  }, [e, n]), [l, i];
}
function dn(e, n, r) {
  const l = P(
    () => JSON.stringify(r),
    [r]
  ), [a, i] = cn(
    e,
    n,
    l
  ), s = P(() => JSON.parse(a), [a]), c = He(
    (o) => i(JSON.stringify(o)),
    []
  );
  return [s, c];
}
const Ke = an(
  "HelpContext",
  void 0
), un = () => on(Ke), hn = ({ children: e }) => {
  const [n, r] = dn(localStorage, "helpEnabled", !0);
  function l() {
    r(!n);
  }
  return /* @__PURE__ */ t(Ke.Provider, { value: { enabled: n, toggleHelp: l }, children: e });
}, mn = () => ye(void 0);
let oe;
const lr = () => {
  const e = ve(oe);
  if (!e)
    throw Error(
      "no environment provider in the hierarchy make sure to add the provider"
    );
  return e;
}, ar = ({
  environment: e,
  children: n
}) => {
  oe = mn();
  const r = ee(!1), [l, a] = T(!1), [i, s] = T(), c = P(() => {
    const o = new Rt({
      url: e.authServerUrl,
      realm: e.realm,
      clientId: e.clientId
    });
    return o.onAuthLogout = () => o.login(), o;
  }, [e]);
  return be(() => {
    if (r.current)
      return;
    c.init({
      onLoad: "check-sso",
      pkceMethod: "S256",
      responseMode: "query"
    }).then(() => a(!0)).catch((d) => s(d)), r.current = !0;
  }, [c]), i ? /* @__PURE__ */ t(rn, { error: i }) : l ? /* @__PURE__ */ t(oe.Provider, { value: { environment: e, keycloak: c }, children: /* @__PURE__ */ t(nn, { children: /* @__PURE__ */ t(hn, { children: n }) }) }) : /* @__PURE__ */ t(ot, {});
};
function ir(e) {
  const n = document.getElementById("environment");
  let r = {};
  try {
    n?.textContent && (r = JSON.parse(n.textContent));
  } catch {
    console.error("Unable to parse environment variables.");
  }
  return { ...e, ...r };
}
const sr = ({
  modalTitle: e,
  continueLabel: n,
  cancelLabel: r,
  buttonTitle: l,
  isDisabled: a,
  buttonVariant: i,
  buttonTestRole: s,
  onContinue: c,
  component: o = D,
  children: d,
  ...m
}) => {
  const [y, h] = T(!1);
  return /* @__PURE__ */ v(Y, { children: [
    /* @__PURE__ */ t(
      o,
      {
        variant: i,
        onClick: () => h(!0),
        isDisabled: a,
        "data-testrole": s,
        children: l
      }
    ),
    /* @__PURE__ */ t(
      Pe,
      {
        variant: "small",
        ...m,
        title: e,
        isOpen: y,
        onClose: () => h(!1),
        actions: [
          /* @__PURE__ */ t(
            D,
            {
              id: "modal-confirm",
              variant: "primary",
              onClick: () => {
                h(!1), c();
              },
              children: n
            },
            "confirm"
          ),
          /* @__PURE__ */ t(
            D,
            {
              id: "modal-cancel",
              variant: "secondary",
              onClick: () => h(!1),
              children: r
            },
            "cancel"
          )
        ],
        children: d
      }
    )
  ] });
}, je = ({ message: e, ...n }) => /* @__PURE__ */ t(ue, { ...n, children: /* @__PURE__ */ t(he, { children: /* @__PURE__ */ t(me, { icon: /* @__PURE__ */ t(Dt, {}), variant: "error", children: e }) }) }), ze = ({
  helpText: e,
  fieldLabelId: n,
  noVerticalAlign: r = !0,
  unWrap: l = !1
}) => {
  const { enabled: a } = un();
  return a ? /* @__PURE__ */ t(ct, { bodyContent: e, children: /* @__PURE__ */ v(Y, { children: [
    !l && /* @__PURE__ */ t(
      "button",
      {
        "data-testid": `help-label-${n}`,
        "aria-label": n,
        onClick: (i) => i.preventDefault(),
        className: "pf-v5-c-form__group-label-help",
        children: /* @__PURE__ */ t(ie, { isInline: r, children: /* @__PURE__ */ t(Te, {}) })
      }
    ),
    l && /* @__PURE__ */ t(ie, { isInline: r, children: /* @__PURE__ */ t(Te, {}) })
  ] }) }) : null;
}, _ = ({
  name: e,
  label: n,
  labelIcon: r,
  error: l,
  children: a,
  ...i
}) => /* @__PURE__ */ v(
  Ve,
  {
    label: n || e,
    fieldId: e,
    labelIcon: r ? /* @__PURE__ */ t(ze, { helpText: r, fieldLabelId: e }) : void 0,
    ...i,
    children: [
      a,
      l && /* @__PURE__ */ t(je, { "data-testid": `${e}-helper`, message: l.message })
    ]
  }
), or = ({
  name: e,
  label: n,
  controller: r,
  labelIcon: l,
  ...a
}) => {
  const {
    control: i,
    formState: { errors: s }
  } = te();
  return /* @__PURE__ */ t(
    _,
    {
      name: e,
      label: n,
      isRequired: r.rules?.required === !0,
      error: s[e],
      labelIcon: l,
      children: /* @__PURE__ */ t(
        U,
        {
          ...r,
          name: e,
          control: i,
          render: ({ field: c }) => {
            const o = !!r.rules?.required, d = r.rules?.min, m = c.value === 0 ? r.defaultValue : c.value, y = (h) => c.onChange(d ? Math.max(h, Number(d)) : h);
            return /* @__PURE__ */ t(
              dt,
              {
                ...a,
                id: e,
                value: m,
                validated: s[e] ? M.error : M.default,
                required: o,
                min: Number(d),
                max: Number(r.rules?.max),
                onPlus: () => y(m + 1),
                onMinus: () => y(m - 1),
                onChange: (h) => {
                  const u = Number(h.currentTarget.value);
                  y(isNaN(u) ? r.defaultValue : u);
                }
              }
            );
          }
        }
      )
    }
  );
}, pn = ({
  hasReveal: e = !0,
  innerRef: n,
  ...r
}) => {
  const { t: l } = Ce(), [a, i] = T(!0);
  return /* @__PURE__ */ v(pe, { children: [
    /* @__PURE__ */ t(se, { isFill: !0, children: /* @__PURE__ */ t(
      Q,
      {
        ...r,
        type: a ? "password" : "text",
        ref: n
      }
    ) }),
    e && /* @__PURE__ */ t(
      D,
      {
        variant: "control",
        "aria-label": l("showPassword"),
        onClick: () => i(!a),
        children: a ? /* @__PURE__ */ t(Et, {}) : /* @__PURE__ */ t(Pt, {})
      }
    )
  ] });
}, Je = Ft(
  (e, n) => /* @__PURE__ */ t(pn, { ...e, innerRef: n })
);
Je.displayName = "PasswordInput";
const cr = (e) => {
  const { labelIcon: n, ...r } = e, l = !!e.rules?.required, a = e.defaultValue ?? "", { field: i, fieldState: s } = Ie({
    ...e,
    defaultValue: a
  });
  return /* @__PURE__ */ v(
    _,
    {
      name: e.name,
      label: e.label,
      labelIcon: n,
      isRequired: l,
      error: s.error,
      children: [
        /* @__PURE__ */ t(
          Je,
          {
            isRequired: l,
            id: e.name,
            "data-testid": e.name,
            validated: s.error ? M.error : M.default,
            isDisabled: e.isDisabled,
            ...r,
            ...i
          }
        ),
        e.helperText && /* @__PURE__ */ t(ue, { children: /* @__PURE__ */ t(he, { children: /* @__PURE__ */ t(me, { children: e.helperText }) }) })
      ]
    }
  );
}, fn = ({
  id: e,
  name: n,
  label: r,
  options: l,
  controller: a,
  labelIcon: i,
  ...s
}) => {
  const {
    control: c,
    formState: { errors: o }
  } = te(), [d, m] = T(!1);
  return /* @__PURE__ */ t(
    _,
    {
      name: n,
      label: r,
      isRequired: !!a.rules?.required,
      error: G(o, n),
      labelIcon: i,
      children: /* @__PURE__ */ t(
        U,
        {
          ...a,
          name: n,
          control: c,
          render: ({ field: { onChange: y, value: h } }) => /* @__PURE__ */ t(
            X,
            {
              ...s,
              onClick: () => m(!d),
              onOpenChange: () => m(!1),
              selected: $(l) ? l.filter(
                (u) => Array.isArray(h) ? h.includes(u.key) : h === u.key
              ).map((u) => u.value) : h,
              toggle: (u) => /* @__PURE__ */ t(
                W,
                {
                  id: e || n.slice(n.lastIndexOf(".") + 1),
                  ref: u,
                  onClick: () => m(!d),
                  isExpanded: d,
                  isFullWidth: !0,
                  status: G(o, n) ? fe.danger : void 0,
                  "aria-label": "toggle",
                  children: $(l) ? l.find(
                    (x) => x.key === (Array.isArray(h) ? h[0] : h)
                  )?.value : h
                }
              ),
              onSelect: (u, x) => {
                const g = x?.toString();
                y(Array.isArray(h) ? [g] : g), m(!1);
              },
              isOpen: d,
              children: /* @__PURE__ */ t(Z, { children: l.map((u) => /* @__PURE__ */ t(ge, { value: L(u), children: ke(u) ? u : u.value }, L(u))) })
            }
          )
        }
      )
    }
  );
}, z = (e) => ke(e) ? e : e.value, gn = ({
  id: e,
  name: n,
  label: r,
  options: l,
  controller: a,
  labelIcon: i,
  placeholderText: s,
  onFilter: c,
  variant: o,
  ...d
}) => {
  const {
    control: m,
    formState: { errors: y }
  } = te(), [h, u] = T(!1), [x, g] = T(""), [f, w] = T(0), E = ee(), C = l.filter(
    (p) => z(p).toLowerCase().startsWith(x.toLowerCase())
  ), b = P(
    () => C.map((p, I) => /* @__PURE__ */ t(
      ge,
      {
        value: L(p),
        isFocused: f === I,
        children: z(p)
      },
      L(p)
    )),
    [f, C]
  ), q = (p, I) => {
    const A = C[f];
    switch (u(!0), p.key) {
      case "Enter": {
        p.preventDefault(), o !== H.typeaheadMulti ? g(z(A)) : g(""), I.onChange(
          Array.isArray(I.value) ? [...I.value, L(A)] : L(A)
        ), u(!1), w(0);
        break;
      }
      case "Tab":
      case "Escape": {
        u(!1), I.onChange(void 0);
        break;
      }
      case "Backspace": {
        o === H.typeahead && I.onChange("");
        break;
      }
      case "ArrowUp":
      case "ArrowDown": {
        p.preventDefault();
        let k = 0;
        p.key === "ArrowUp" && (f === 0 ? k = l.length - 1 : k = f - 1), p.key === "ArrowDown" && (f === l.length - 1 ? k = 0 : k = f + 1), w(k);
        break;
      }
    }
  };
  return /* @__PURE__ */ t(
    _,
    {
      name: n,
      label: r,
      isRequired: !!a.rules?.required,
      error: G(y, n),
      labelIcon: i,
      children: /* @__PURE__ */ t(
        U,
        {
          ...a,
          name: n,
          control: m,
          render: ({ field: p }) => /* @__PURE__ */ t(
            X,
            {
              ...d,
              onClick: () => u(!h),
              onOpenChange: () => u(!1),
              selected: $(l) ? l.filter(
                (I) => Array.isArray(p.value) ? p.value.includes(I.key) : p.value === I.key
              ).map((I) => I.value) : p.value,
              toggle: (I) => /* @__PURE__ */ t(
                W,
                {
                  ref: I,
                  id: e || n.slice(n.lastIndexOf(".") + 1),
                  variant: "typeahead",
                  onClick: () => u(!h),
                  isExpanded: h,
                  isFullWidth: !0,
                  status: G(y, n) ? fe.danger : void 0,
                  children: /* @__PURE__ */ v(Me, { isPlain: !0, children: [
                    /* @__PURE__ */ t(
                      Ne,
                      {
                        placeholder: s,
                        value: o === H.typeahead && p.value ? $(l) ? l.find(
                          (A) => A.key === (Array.isArray(p.value) ? p.value[0] : p.value)
                        )?.value : p.value : x,
                        onClick: () => u(!h),
                        onChange: (A, k) => {
                          g(k), c?.(k);
                        },
                        onKeyDown: (A) => q(A, p),
                        autoComplete: "off",
                        innerRef: E,
                        role: "combobox",
                        isExpanded: h,
                        "aria-controls": "select-typeahead-listbox",
                        children: o === H.typeaheadMulti && Array.isArray(p.value) && /* @__PURE__ */ t(qe, { "aria-label": "Current selections", children: p.value.map(
                          (A, k) => /* @__PURE__ */ t(
                            Le,
                            {
                              onClick: (S) => {
                                S.stopPropagation(), p.onChange(
                                  p.value.filter(
                                    (F) => F !== L(A)
                                  )
                                );
                              },
                              children: $(l) ? l.find((S) => A === S.key)?.value : z(A)
                            },
                            k
                          )
                        ) })
                      }
                    ),
                    /* @__PURE__ */ t(Be, { children: !!x && /* @__PURE__ */ t(
                      D,
                      {
                        variant: "plain",
                        onClick: () => {
                          p.onChange(void 0), g(""), E?.current?.focus();
                        },
                        "aria-label": "Clear input value",
                        children: /* @__PURE__ */ t(Ge, { "aria-hidden": !0 })
                      }
                    ) })
                  ] })
                }
              ),
              onSelect: (I, A) => {
                I?.stopPropagation();
                const k = A?.toString();
                o === H.typeaheadMulti && Array.isArray(p.value) ? p.value.includes(k) ? p.onChange(
                  p.value.filter((S) => S !== k)
                ) : p.onChange([...p.value, k]) : (p.onChange(Array.isArray(p.value) ? [k] : k), u(!1));
              },
              isOpen: h,
              children: /* @__PURE__ */ t(Z, { children: b })
            }
          )
        }
      )
    }
  );
};
var H = /* @__PURE__ */ ((e) => (e.single = "single", e.typeahead = "typeahead", e.typeaheadMulti = "typeaheadMulti", e))(H || {});
const $ = (e) => typeof e[0] != "string", ke = (e) => typeof e == "string", L = (e) => ke(e) ? e : e.key, yn = ({
  variant: e = "single",
  ...n
}) => e === "single" ? /* @__PURE__ */ t(fn, { ...n }) : /* @__PURE__ */ t(gn, { ...n, variant: e }), dr = ({
  labelOn: e,
  stringify: n,
  defaultValue: r,
  labelIcon: l,
  ...a
}) => {
  const s = r ?? (n ? "false" : !1), { control: c } = te();
  return /* @__PURE__ */ t(
    _,
    {
      hasNoPaddingTop: !0,
      name: a.name,
      isRequired: a.rules?.required === !0,
      label: a.label,
      labelIcon: l,
      children: /* @__PURE__ */ t(
        U,
        {
          control: c,
          name: a.name,
          defaultValue: s,
          render: ({ field: { onChange: o, value: d } }) => /* @__PURE__ */ t(
            ut,
            {
              ...a,
              id: a.name,
              "data-testid": a.name,
              label: e,
              isChecked: n ? d === "true" : d,
              onChange: (m, y) => {
                const h = n ? y.toString() : y;
                a.onChange?.(m, y), o(h);
              }
            }
          )
        }
      )
    }
  );
}, ur = (e) => {
  const n = !!e.rules?.required, r = e.defaultValue ?? "", { field: l, fieldState: a } = Ie({
    ...e,
    defaultValue: r
  });
  return /* @__PURE__ */ t(
    _,
    {
      isRequired: n,
      label: e.label,
      labelIcon: e.labelIcon,
      name: e.name,
      error: a.error,
      children: /* @__PURE__ */ t(
        _e,
        {
          isRequired: n,
          id: e.name,
          "data-testid": e.name,
          validated: a.error ? M.error : M.default,
          isDisabled: e.isDisabled,
          rows: e.rows,
          ...l
        }
      )
    }
  );
}, hr = (e) => {
  const { labelIcon: n, ...r } = e, l = !!e.rules?.required, a = e.defaultValue ?? "", { field: i, fieldState: s } = Ie({
    ...e,
    defaultValue: a
  });
  return /* @__PURE__ */ v(
    _,
    {
      name: e.name,
      label: e.label,
      labelIcon: n,
      isRequired: l,
      error: s.error,
      children: [
        /* @__PURE__ */ t(
          Q,
          {
            isRequired: l,
            id: e.name,
            "data-testid": e.name,
            validated: s.error ? M.error : M.default,
            isDisabled: e.isDisabled,
            ...r,
            ...i
          }
        ),
        e.helperText && /* @__PURE__ */ t(ue, { children: /* @__PURE__ */ t(he, { children: /* @__PURE__ */ t(me, { children: e.helperText }) }) })
      ]
    }
  );
}, vn = _e, mr = ({ icon: e }) => {
  const n = bn(e);
  return /* @__PURE__ */ t(ie, { size: "lg", children: /* @__PURE__ */ t(n, { alt: e }) });
};
function bn(e) {
  switch (e) {
    case "github":
      return Kt;
    case "facebook":
      return Wt;
    case "gitlab":
      return Gt;
    case "google":
      return $t;
    case "linkedin":
    case "linkedin-openid-connect":
      return Ut;
    case "openshift-v3":
    case "openshift-v4":
      return Ht;
    case "stackoverflow":
      return _t;
    case "twitter":
      return Bt;
    case "microsoft":
      return Lt;
    case "bitbucket":
      return qt;
    case "instagram":
      return Nt;
    case "paypal":
      return Mt;
    default:
      return Vt;
  }
}
const Cn = "_title_180i0_2", In = {
  title: Cn
}, Ye = ({
  id: e,
  title: n,
  headingLevel: r = "h1",
  size: l = "xl",
  ...a
}) => /* @__PURE__ */ t(
  ht,
  {
    headingLevel: r,
    size: l,
    className: In.title,
    id: e,
    tabIndex: 0,
    ...a,
    children: n
  }
), kn = ({
  title: e,
  children: n,
  scrollId: r,
  className: l
}) => {
  const a = Ot();
  return /* @__PURE__ */ v(mt, { id: a, className: l, isFlat: !0, children: [
    /* @__PURE__ */ t(pt, { className: "kc-form-panel__header", children: /* @__PURE__ */ t(ft, { tabIndex: 0, children: /* @__PURE__ */ t(Ye, { id: r, title: e }) }) }),
    /* @__PURE__ */ t(gt, { className: "kc-form-panel__body", children: n })
  ] });
}, xn = (e) => {
  const { title: n, children: r, scrollId: l, ...a } = e;
  return /* @__PURE__ */ t("section", { ...a, style: { marginTop: "var(--pf-v5-global--spacer--lg)" }, children: /* @__PURE__ */ v(Y, { children: [
    /* @__PURE__ */ t(Ye, { id: l, title: n }),
    r
  ] }) });
}, wn = "_panel_1cdve_1", An = "_sticky_1cdve_5", Se = {
  panel: wn,
  sticky: An
}, Tn = "kc-main-content-page-container", Fe = (e) => e.replace(/\s+/g, "-"), Sn = ({
  label: e,
  sections: n,
  borders: r = !1,
  ...l
}) => {
  const a = P(
    () => n.filter(({ isHidden: i }) => !i),
    [n]
  );
  return /* @__PURE__ */ v(yt, { hasGutter: !0, ...l, children: [
    /* @__PURE__ */ t(xe, { md: 8, sm: 12, children: a.map(({ title: i, panel: s }) => {
      const c = Fe(i.toLowerCase());
      return /* @__PURE__ */ t(Ue, { children: r ? /* @__PURE__ */ t(
        kn,
        {
          scrollId: c,
          title: i,
          className: Se.panel,
          children: s
        }
      ) : /* @__PURE__ */ t(xn, { scrollId: c, title: i, children: s }) }, i);
    }) }),
    /* @__PURE__ */ t(xe, { md: 4, sm: 12, order: { default: "-1", md: "1" }, children: /* @__PURE__ */ t(vt, { className: Se.sticky, children: /* @__PURE__ */ t(
      bt,
      {
        isVertical: !0,
        scrollableSelector: `#${Tn}`,
        label: e,
        offset: 100,
        children: a.map(({ title: i }) => {
          const s = Fe(i.toLowerCase());
          return (
            // note that JumpLinks currently does not work with spaces in the href
            /* @__PURE__ */ t(
              Ct,
              {
                href: `#${s}`,
                "data-testid": `jump-link-${s}`,
                children: i
              },
              i
            )
          );
        })
      }
    ) }) })
  ] });
}, Fn = (e, n, r) => (e.isValid || r) && (e.isDirty || n) && !e.isLoading && !e.isValidating && !e.isSubmitting, pr = ({
  formState: e,
  isDisabled: n = !1,
  allowInvalid: r = !1,
  allowNonDirty: l = !1,
  children: a,
  ...i
}) => /* @__PURE__ */ t(
  D,
  {
    variant: "primary",
    isDisabled: e && !Fn(e, l, r) || n,
    ...i,
    type: "submit",
    children: a
  }
), On = (e) => {
  try {
    return new Intl.DisplayNames([e], { type: "language" }).of(e);
  } catch {
    return e;
  }
}, Rn = ({
  t: e,
  form: n,
  supportedLocales: r,
  currentLocale: l
}) => {
  const a = P(
    () => r.map((i) => ({
      key: i,
      value: e(`locale_${i}`, On(i) ?? i)
    })).sort((i, s) => i.value.localeCompare(s.value, l)),
    [r, l, e]
  );
  return a.length ? /* @__PURE__ */ t(Yt, { ...n, children: /* @__PURE__ */ t(
    yn,
    {
      "data-testid": "locale-select",
      name: "attributes.locale",
      label: e("selectALocale"),
      controller: { defaultValue: "" },
      options: a,
      variant: a.length >= 10 ? "typeahead" : "single"
    }
  ) }) : null;
}, Qe = (e) => e?.includes("${"), Xe = (e) => e.substring(2, e.length - 1), B = (e, n, r) => (Qe(n) ? e(Xe(n)) : n) || r, ce = (e, n) => B(e, n.displayName, n.name), Dn = ["username", "firstName", "lastName", "email"], Ze = (e) => e && Dn.includes(e), N = (e) => `${Ze(e) ? "" : "attributes."}${e?.replaceAll(
  ".",
  "🍺"
)}`, fr = (e) => e.replaceAll(".", "🍺"), gr = (e) => e.replaceAll("🍺", ".");
function yr(e, n, r) {
  (e.responseData.errors !== void 0 ? e.responseData.errors : [e.responseData]).forEach((l) => {
    const a = Object.assign(
      {},
      l.params?.map((i) => r(Qe(i.toString()) ? Xe(i) : i))
    );
    n(N(l.field), {
      message: r(l.errorMessage, {
        ...a,
        defaultValue: l.errorMessage || l.field
      }),
      type: "server"
    });
  });
}
function ne({
  required: e,
  validators: n
}) {
  return e || En(n);
}
function En(e) {
  return e && "length" in e && "min" in e.length && typeof e.length.min == "number" ? e.length.min > 0 : !1;
}
function vr(e) {
  if (typeof e != "object" || e === null || !("responseData" in e))
    return !1;
  const { responseData: n } = e;
  return Oe(n) ? !0 : typeof n != "object" || n === null || !("errors" in n) || !Array.isArray(n.errors) ? !1 : n.errors.every(Oe);
}
function Oe(e) {
  return !(typeof e != "object" || e === null || !("field" in e) || typeof e.field != "string" || !("errorMessage" in e) || typeof e.errorMessage != "string");
}
const K = ({
  t: e,
  form: n,
  attribute: r,
  renderer: l,
  children: a
}) => {
  const i = B(
    e,
    r.annotations?.inputHelperTextBefore
  ), {
    formState: { errors: s }
  } = n, c = l?.(r), o = G(s, N(r.name));
  return /* @__PURE__ */ v(
    Ve,
    {
      label: ce(e, r) || "",
      fieldId: r.name,
      isRequired: ne(r),
      labelIcon: i ? /* @__PURE__ */ t(ze, { helpText: i, fieldLabelId: r.name }) : void 0,
      children: [
        c ? /* @__PURE__ */ v(pe, { children: [
          a,
          c
        ] }) : a,
        o && /* @__PURE__ */ t(
          je,
          {
            "data-testid": `${r.name}-helper`,
            message: o.message
          }
        )
      ]
    },
    r.name
  );
}, Pn = ({
  t: e,
  form: n,
  attribute: r,
  renderer: l,
  ...a
}) => /* @__PURE__ */ t(K, { t: e, form: n, attribute: r, renderer: l, children: /* @__PURE__ */ t(
  Vn,
  {
    t: e,
    form: n,
    "aria-label": ce(e, r),
    name: N(r.name),
    addButtonLabel: e("addMultivaluedLabel", {
      fieldLabel: ce(e, r)
    }),
    ...a
  }
) }), Vn = ({
  t: e,
  name: n,
  inputType: r,
  form: l,
  addButtonLabel: a,
  isDisabled: i = !1,
  defaultValue: s,
  id: c,
  ...o
}) => {
  const { register: d, setValue: m, control: y } = l, h = Qt({
    name: n,
    control: y,
    defaultValue: s || ""
  }), u = P(() => Array.isArray(h) && h.length !== 0 ? h : s || [""], [h]), x = (C) => {
    w([...u.slice(0, C), ...u.slice(C + 1)]);
  }, g = () => {
    w([...u, ""]);
  }, f = (C, b) => {
    w([...u.slice(0, C), b, ...u.slice(C + 1)]);
  }, w = (C) => {
    const b = C.flatMap((q) => q);
    m(n, b, {
      shouldDirty: !0
    });
  }, E = r.startsWith("html") ? r.substring(6) : "text";
  return be(() => {
    d(n);
  }, [d]), /* @__PURE__ */ t("div", { id: c, children: u.map((C, b) => /* @__PURE__ */ v(Ue, { children: [
    /* @__PURE__ */ v(pe, { children: [
      /* @__PURE__ */ t(se, { isFill: !0, children: /* @__PURE__ */ t(
        Q,
        {
          "data-testid": n + b,
          onChange: (q, p) => f(b, p),
          name: `${n}.${b}.value`,
          value: C,
          isDisabled: i,
          type: E,
          ...o
        }
      ) }),
      /* @__PURE__ */ t(se, { children: /* @__PURE__ */ t(
        D,
        {
          "data-testid": "remove" + b,
          variant: we.link,
          onClick: () => x(b),
          tabIndex: -1,
          "aria-label": e("remove"),
          isDisabled: u.length === 1 || i,
          children: /* @__PURE__ */ t(jt, {})
        }
      ) })
    ] }),
    b === u.length - 1 && /* @__PURE__ */ v(
      D,
      {
        variant: we.link,
        onClick: g,
        tabIndex: -1,
        "aria-label": e("add"),
        "data-testid": "addValue",
        isDisabled: !C || i,
        children: [
          /* @__PURE__ */ t(zt, {}),
          " ",
          e(a || "add")
        ]
      }
    )
  ] }, b)) });
}, Re = (e) => {
  const { form: n, inputType: r, attribute: l } = e, a = ne(l), i = r.startsWith("multiselect"), s = i ? It : kt, c = l.validators?.options?.options || [], o = l.annotations?.inputOptionLabels || {};
  return /* @__PURE__ */ t(K, { ...e, children: /* @__PURE__ */ t(
    U,
    {
      name: N(l.name),
      control: n.control,
      defaultValue: "",
      render: ({ field: d }) => /* @__PURE__ */ t(Y, { children: c.map((m) => /* @__PURE__ */ t(
        s,
        {
          id: m,
          "data-testid": m,
          label: B(e.t, o[m], m),
          value: m,
          isChecked: d.value.includes(m),
          onChange: () => {
            i ? d.value.includes(m) ? d.onChange(
              d.value.filter((y) => y !== m)
            ) : d.onChange([...d.value, m]) : d.onChange([m]);
          },
          readOnly: l.readOnly,
          isRequired: a
        },
        m
      )) })
    }
  ) });
}, Mn = ({
  toggleId: e,
  onToggle: n,
  onSelect: r,
  selections: l,
  isOpen: a,
  menuAppendTo: i,
  direction: s,
  width: c,
  maxHeight: o,
  toggleIcon: d,
  className: m,
  children: y,
  ...h
}) => {
  const [u, x] = T(!1), g = ee(), f = () => {
    x(!u), n(!u);
  }, w = () => i === "parent" && g.current?.parentElement || "inline", E = $e.toArray(
    y
  );
  return /* @__PURE__ */ t(
    X,
    {
      ref: g,
      maxMenuHeight: J(o),
      isScrollable: !0,
      popperProps: {
        appendTo: w(),
        direction: s,
        width: J(c)
      },
      ...h,
      onClick: f,
      onOpenChange: () => x(!1),
      selected: l,
      onSelect: (C, b) => {
        r?.(b || ""), f();
      },
      toggle: (C) => /* @__PURE__ */ t(
        W,
        {
          id: e,
          ref: C,
          className: m,
          onClick: f,
          isExpanded: a,
          "aria-label": h["aria-label"],
          icon: d,
          isFullWidth: !0,
          children: E.find((b) => b.props.value === l)?.props.children || l || h["aria-label"]
        }
      ),
      isOpen: a,
      children: /* @__PURE__ */ t(Z, { children: y })
    }
  );
}, Nn = ({
  toggleId: e,
  onSelect: n,
  onToggle: r,
  onFilter: l,
  variant: a,
  validated: i,
  placeholderText: s,
  maxHeight: c,
  width: o,
  toggleIcon: d,
  direction: m,
  selections: y,
  typeAheadAriaLabel: h,
  chipGroupComponent: u,
  chipGroupProps: x,
  footer: g,
  children: f,
  ...w
}) => {
  const [E, C] = T(""), [b, q] = T(0), p = ee(), I = $e.toArray(
    f
  ), A = () => {
    r?.(!w.isOpen);
  }, k = (S) => {
    const F = I[b];
    switch (r?.(!0), S.key) {
      case "Enter": {
        S.preventDefault(), a !== V.typeaheadMulti ? C(F.props.value) : C(""), n?.(F.props.value), r?.(!1), q(0);
        break;
      }
      case "Escape": {
        r?.(!1);
        break;
      }
      case "Backspace": {
        a === V.typeahead && n?.("");
        break;
      }
      case "ArrowUp":
      case "ArrowDown": {
        S.preventDefault();
        let O = 0;
        S.key === "ArrowUp" && (b === 0 ? O = I.length - 1 : O = b - 1), S.key === "ArrowDown" && (b === I.length - 1 ? O = 0 : O = b + 1), q(O);
        break;
      }
    }
  };
  return /* @__PURE__ */ v(
    X,
    {
      ...w,
      onClick: A,
      onOpenChange: () => r?.(!1),
      onSelect: (S, F) => n?.(F || ""),
      maxMenuHeight: J(c),
      popperProps: { direction: m, width: J(o) },
      toggle: (S) => /* @__PURE__ */ t(
        W,
        {
          ref: S,
          id: e,
          variant: "typeahead",
          onClick: () => r?.(!0),
          icon: d,
          isExpanded: w.isOpen,
          isFullWidth: !0,
          status: i === "error" ? fe.danger : void 0,
          children: /* @__PURE__ */ v(Me, { isPlain: !0, children: [
            /* @__PURE__ */ t(
              Ne,
              {
                placeholder: s,
                value: a === V.typeahead && y ? y : E,
                onClick: A,
                onChange: (F, O) => {
                  C(O), l?.(O);
                },
                onKeyDown: (F) => k(F),
                autoComplete: "off",
                innerRef: p,
                role: "combobox",
                isExpanded: w.isOpen,
                "aria-controls": "select-typeahead-listbox",
                "aria-label": h,
                children: a === V.typeaheadMulti && Array.isArray(y) && (u || /* @__PURE__ */ t(qe, { ...x, children: y.map((F, O) => /* @__PURE__ */ t(
                  Le,
                  {
                    onClick: (et) => {
                      et.stopPropagation(), n?.(F);
                    },
                    children: F
                  },
                  O
                )) }))
              }
            ),
            /* @__PURE__ */ t(Be, { children: !!E && /* @__PURE__ */ t(
              D,
              {
                variant: "plain",
                onClick: () => {
                  n?.(""), C(""), l?.(""), p?.current?.focus();
                },
                "aria-label": "Clear input value",
                children: /* @__PURE__ */ t(Ge, { "aria-hidden": !0 })
              }
            ) })
          ] })
        }
      ),
      children: [
        /* @__PURE__ */ t(Z, { children: f }),
        g && /* @__PURE__ */ t(xt, { children: g })
      ]
    }
  );
};
var V = /* @__PURE__ */ ((e) => (e.single = "single", e.typeahead = "typeahead", e.typeaheadMulti = "typeaheadMulti", e))(V || {});
const J = (e) => typeof e == "number" ? e + "px" : e, qn = ({
  variant: e = "single",
  ...n
}) => e === "single" ? /* @__PURE__ */ t(Mn, { ...n }) : /* @__PURE__ */ t(Nn, { ...n, variant: e }), De = (e) => {
  const { t: n, form: r, inputType: l, attribute: a } = e, [i, s] = T(!1), [c, o] = T(""), d = l === "multiselect", m = (g, f) => {
    d ? f.value.includes(g) ? f.onChange(f.value.filter((w) => w !== g)) : Array.isArray(f.value) ? f.onChange([...f.value, g]) : f.onChange([g]) : f.onChange(g === f.value ? "" : g);
  }, y = a.validators?.options?.options || [], h = a.annotations?.inputOptionLabels || {}, u = (g) => B(e.t, h[g], g), x = (g) => y.filter(
    (f) => u(f).toLowerCase().includes(c.toLowerCase())
  ).map((f) => /* @__PURE__ */ t(
    ge,
    {
      selected: g === f,
      value: f,
      children: u(f)
    },
    f
  ));
  return /* @__PURE__ */ t(K, { ...e, children: /* @__PURE__ */ t(
    U,
    {
      name: N(a.name),
      defaultValue: "",
      control: r.control,
      render: ({ field: g }) => /* @__PURE__ */ t(
        qn,
        {
          toggleId: a.name,
          onToggle: (f) => s(f),
          onClear: () => m("", g),
          onSelect: (f) => {
            const w = f.toString();
            m(w, g), Array.isArray(g.value) || s(!1);
          },
          selections: d && Array.isArray(g.value) ? g.value : u(g.value),
          variant: d ? V.typeaheadMulti : y.length >= 10 ? V.typeahead : V.single,
          "aria-label": n("selectOne"),
          isOpen: i,
          isDisabled: a.readOnly,
          onFilter: (f) => (o(f), x(g.value)),
          children: x(g.value)
        }
      )
    }
  ) });
}, Ln = (e) => {
  const { form: n, attribute: r } = e, l = ne(r);
  return /* @__PURE__ */ t(K, { ...e, children: /* @__PURE__ */ t(
    vn,
    {
      id: r.name,
      "data-testid": r.name,
      ...n.register(N(r.name)),
      cols: r.annotations?.inputTypeCols,
      rows: r.annotations?.inputTypeRows,
      readOnly: r.readOnly,
      isRequired: l
    }
  ) });
}, R = (e) => {
  const { form: n, inputType: r, attribute: l } = e, a = ne(l), i = r.startsWith("html") ? r.substring(6) : "text";
  return /* @__PURE__ */ t(K, { ...e, children: /* @__PURE__ */ t(
    Q,
    {
      id: l.name,
      "data-testid": l.name,
      type: i,
      placeholder: B(
        e.t,
        l.annotations?.inputTypePlaceholder
      ),
      readOnly: l.readOnly,
      isRequired: a,
      ...n.register(N(l.name))
    }
  ) });
}, de = {
  text: R,
  textarea: Ln,
  select: De,
  "select-radiobuttons": Re,
  multiselect: De,
  "multiselect-checkboxes": Re,
  "html5-email": R,
  "html5-tel": R,
  "html5-url": R,
  "html5-number": R,
  "html5-range": R,
  "html5-datetime-local": R,
  "html5-date": R,
  "html5-month": R,
  "html5-time": R,
  "multi-input": Pn
}, br = ({
  t: e,
  form: n,
  userProfileMetadata: r,
  supportedLocales: l,
  currentLocale: a,
  hideReadOnly: i = !1,
  renderer: s
}) => {
  const c = P(() => {
    if (!r.attributes)
      return [];
    const o = i ? r.attributes.filter(({ readOnly: d }) => !d) : r.attributes;
    return [
      // Insert an empty group for attributes without a group.
      { name: void 0 },
      ...r.groups ?? []
    ].map((d) => ({
      group: d,
      attributes: o.filter(
        (m) => m.group === d.name
      )
    }));
  }, [
    i,
    r.groups,
    r.attributes
  ]);
  return c.length === 0 ? null : /* @__PURE__ */ t(
    Sn,
    {
      label: e("jumpToSection"),
      sections: c.filter((o) => o.attributes.length > 0).map(({ group: o, attributes: d }) => ({
        title: B(e, o.displayHeader, o.name) || e("general"),
        panel: /* @__PURE__ */ v("div", { className: "pf-v5-c-form", children: [
          o.displayDescription && /* @__PURE__ */ t(ae, { className: "pf-v5-u-pb-lg", children: B(e, o.displayDescription, "") }),
          d.map((m) => /* @__PURE__ */ t(
            Bn,
            {
              t: e,
              form: n,
              supportedLocales: l,
              currentLocale: a,
              renderer: s,
              attribute: m
            },
            m.name
          ))
        ] })
      }))
    }
  );
}, Bn = ({
  t: e,
  form: n,
  renderer: r,
  supportedLocales: l,
  currentLocale: a,
  attribute: i
}) => {
  const s = n.watch(
    N(i.name)
  ), c = P(() => Hn(i), [i]), o = i.multivalued || $n(s) && i.annotations?.inputType === void 0 ? de["multi-input"] : de[c];
  return i.name === "locale" ? /* @__PURE__ */ t(
    Rn,
    {
      form: n,
      supportedLocales: l,
      currentLocale: a,
      t: e,
      attribute: i
    }
  ) : /* @__PURE__ */ t(
    o,
    {
      t: e,
      form: n,
      inputType: c,
      attribute: i,
      renderer: r
    }
  );
}, _n = "text";
function Hn(e) {
  if (Ze(e.name))
    return "text";
  const n = e.annotations?.inputType;
  return Un(n) ? n : _n;
}
const Un = (e) => typeof e == "string" && e in de, $n = (e) => Array.isArray(e) && e.length > 1, Gn = ({
  className: e = "",
  border: n,
  size: r = "md"
}) => /* @__PURE__ */ v(
  "svg",
  {
    className: tn(
      j.avatar,
      j.modifiers[r],
      n === "light" && j.modifiers.light,
      n === "dark" && j.modifiers.dark,
      e
    ),
    enableBackground: "new 0 0 36 36",
    version: "1.1",
    viewBox: "0 0 36 36",
    xmlns: "http://www.w3.org/2000/svg",
    children: [
      /* @__PURE__ */ t(
        "circle",
        {
          style: { fillRule: "evenodd", clipRule: "evenodd", fill: "#FFFFFF" },
          cx: "18",
          cy: "18.5",
          r: "18"
        }
      ),
      /* @__PURE__ */ t("defs", { children: /* @__PURE__ */ t(
        "filter",
        {
          id: "b",
          x: "5.2",
          y: "7.2",
          width: "25.6",
          height: "53.6",
          filterUnits: "userSpaceOnUse",
          children: /* @__PURE__ */ t("feColorMatrix", { values: "1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0" })
        }
      ) }),
      /* @__PURE__ */ t(
        "mask",
        {
          id: "a",
          x: "5.2",
          y: "7.2",
          width: "25.6",
          height: "53.6",
          maskUnits: "userSpaceOnUse",
          children: /* @__PURE__ */ t("g", { style: { filter: 'url("#b")' }, children: /* @__PURE__ */ t(
            "circle",
            {
              style: { fillRule: "evenodd", clipRule: "evenodd", fill: "#FFFFFF" },
              cx: "18",
              cy: "18.5",
              r: "18"
            }
          ) })
        }
      ),
      /* @__PURE__ */ t("g", { style: { filter: 'url("#a")' }, children: /* @__PURE__ */ v("g", { transform: "translate(5.04 6.88)", children: [
        /* @__PURE__ */ t(
          "path",
          {
            style: {
              fillRule: "evenodd",
              clipRule: "evenodd",
              fill: "#BBBBBB"
            },
            d: "m22.6 18.1c-1.1-1.4-2.3-2.2-3.5-2.6s-1.8-0.6-6.3-0.6-6.1 0.7-6.1 0.7 0 0 0 0c-1.2 0.4-2.4 1.2-3.4 2.6-2.3 2.8-3.2 12.3-3.2 14.8 0 3.2 0.4 12.3 0.6 15.4 0 0-0.4 5.5 4 5.5l-0.3-6.3-0.4-3.5 0.2-0.9c0.9 0.4 3.6 1.2 8.6 1.2 5.3 0 8-0.9 8.8-1.3l0.2 1-0.2 3.6-0.3 6.3c3 0.1 3.7-3 3.8-4.4s0.6-12.6 0.6-16.5c0.1-2.6-0.8-12.1-3.1-15z"
          }
        ),
        /* @__PURE__ */ t(
          "path",
          {
            style: {
              opacity: 0.1,
              fillRule: "evenodd",
              clipRule: "evenodd"
            },
            d: "m22.5 26c-0.1-2.1-1.5-2.8-4.8-2.8l2.2 9.6s1.8-1.7 3-1.8c0 0-0.4-4.6-0.4-5z"
          }
        ),
        /* @__PURE__ */ t(
          "path",
          {
            style: {
              fillRule: "evenodd",
              clipRule: "evenodd",
              fill: "#BBBBBB"
            },
            d: "m12.7 13.2c-3.5 0-6.4-2.9-6.4-6.4s2.9-6.4 6.4-6.4 6.4 2.9 6.4 6.4-2.8 6.4-6.4 6.4z"
          }
        ),
        /* @__PURE__ */ t(
          "path",
          {
            style: {
              opacity: 0.08,
              fillRule: "evenodd",
              clipRule: "evenodd",
              fill: "#231F20"
            },
            d: "m9.4 6.8c0-3 2.1-5.5 4.9-6.3-0.5-0.1-1-0.2-1.6-0.2-3.5 0-6.4 2.9-6.4 6.4s2.9 6.4 6.4 6.4c0.6 0 1.1-0.1 1.6-0.2-2.8-0.6-4.9-3.1-4.9-6.1z"
          }
        ),
        /* @__PURE__ */ t(
          "path",
          {
            style: {
              opacity: 0.1,
              fillRule: "evenodd",
              clipRule: "evenodd"
            },
            d: "m8.3 22.4c-2 0.4-2.9 1.4-3.1 3.5l-0.6 18.6s1.7 0.7 3.6 0.9l0.1-23z"
          }
        )
      ] }) })
    ]
  }
), Ee = ({
  isKebab: e = !1,
  title: n,
  dropDownItems: r,
  ...l
}) => {
  const [a, i] = T(!1);
  return /* @__PURE__ */ t(
    wt,
    {
      ...l,
      popperProps: {
        position: "right"
      },
      toggle: (s) => /* @__PURE__ */ t(
        W,
        {
          "data-testid": `${l["data-testid"]}-toggle`,
          ref: s,
          onClick: () => i(!a),
          isExpanded: a,
          variant: e ? "plain" : "default",
          children: e ? /* @__PURE__ */ t(Jt, {}) : n
        }
      ),
      isOpen: a,
      children: /* @__PURE__ */ t(At, { children: r })
    }
  );
};
function Wn(e, n) {
  if (!e)
    return n("unknownUser");
  const r = e.given_name, l = e.family_name, a = e.preferred_username;
  return r && l ? n("fullName", { givenName: r, familyName: l }) : r || l || a || n("unknownUser");
}
const Cr = ({
  keycloak: e,
  brand: { href: n, ...r },
  avatar: l,
  features: {
    hasLogout: a = !0,
    hasManageAccount: i = !0,
    hasUsername: s = !0
  } = {},
  kebabDropdownItems: c,
  dropdownItems: o = [],
  toolbarItems: d,
  ...m
}) => {
  const { t: y } = Ce(), h = [];
  i && h.push(
    /* @__PURE__ */ t(
      Ae,
      {
        onClick: () => e.accountManagement(),
        children: y("manageAccount")
      },
      "manageAccount"
    )
  ), a && h.push(
    /* @__PURE__ */ t(Ae, { onClick: () => e.logout(), children: y("signOut") }, "signOut")
  );
  const u = e.idTokenParsed?.picture;
  return /* @__PURE__ */ t(
    Xt,
    {
      ...m,
      logo: /* @__PURE__ */ t(Tt, { ...r }),
      logoProps: { href: n },
      headerTools: /* @__PURE__ */ v(Zt, { children: [
        /* @__PURE__ */ v(en, { children: [
          /* @__PURE__ */ t(
            le,
            {
              visibility: {
                md: "hidden"
              },
              children: /* @__PURE__ */ t(
                Ee,
                {
                  "data-testid": "options-kebab",
                  isKebab: !0,
                  dropDownItems: [
                    ...c || o,
                    h
                  ]
                }
              )
            }
          ),
          /* @__PURE__ */ t(le, { children: d }),
          /* @__PURE__ */ t(
            le,
            {
              visibility: {
                default: "hidden",
                md: "visible"
              },
              children: /* @__PURE__ */ t(
                Ee,
                {
                  "data-testid": "options",
                  dropDownItems: [...o, h],
                  title: s ? Wn(e.idTokenParsed, y) : void 0
                }
              )
            }
          )
        ] }),
        u || l?.src ? /* @__PURE__ */ t(St, { src: u, alt: y("avatar"), ...l }) : /* @__PURE__ */ t(Gn, { ...l })
      ] })
    }
  );
};
export {
  nn as AlertProvider,
  sr as ContinueCancelModal,
  rn as ErrorPage,
  je as FormErrorText,
  kn as FormPanel,
  pr as FormSubmitButton,
  hn as Help,
  ze as HelpItem,
  mr as IconMapper,
  Cr as KeycloakMasthead,
  ar as KeycloakProvider,
  qn as KeycloakSelect,
  vn as KeycloakTextArea,
  or as NumberControl,
  cr as PasswordControl,
  Je as PasswordInput,
  Sn as ScrollForm,
  yn as SelectControl,
  H as SelectVariant,
  dr as SwitchControl,
  ur as TextAreaControl,
  hr as TextControl,
  br as UserProfileFields,
  fr as beerify,
  an as createNamedContext,
  gr as debeerify,
  ir as getInjectedEnvironment,
  sn as isDefined,
  vr as isUserProfileError,
  B as label,
  Tn as mainPageContentId,
  yr as setUserProfileServerError,
  rr as useAlerts,
  lr as useEnvironment,
  un as useHelp,
  on as useRequiredContext,
  dn as useStoredState
};
